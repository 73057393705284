






















import { defineComponent } from "@vue/composition-api";
import { auth, providers } from "@/services/firebase";

export default defineComponent({
  name: "LinkProviders",
  setup: () => {
    const currentUser = auth.currentUser;

    const linkWith = (providerType: "facebook" | "google") => {
      if (providerType === "facebook") {
        const facebookProvider = new providers.FacebookAuthProvider();
        facebookProvider.addScope("public_profile");
        facebookProvider.addScope("user_birthday");
        facebookProvider.addScope("user_events");
        facebookProvider.addScope("user_friends");
        facebookProvider.addScope("user_gender");
        facebookProvider.addScope("user_hometown");
        facebookProvider.addScope("user_likes");
        facebookProvider.addScope("user_link");
        facebookProvider.addScope("user_location");
        facebookProvider.addScope("user_photos");
        facebookProvider.addScope("user_posts");
        facebookProvider.addScope("user_tagged_places");
        facebookProvider.addScope("user_videos");
        currentUser?.linkWithRedirect(facebookProvider);
      }
      else if (providerType === "google") {
        const googleProvider = new providers.GoogleAuthProvider();
        googleProvider.addScope("profile");
        googleProvider.addScope("email");
        currentUser?.linkWithRedirect(googleProvider);
      }
    };

    const hasProvider = (providerType: "facebook.com" | "google.com") =>
      currentUser?.providerData
        .map((p) => {
          if (p) return p.providerId;
          return "";
        })
        .includes(providerType);

    return {
      hasProvider,
      linkWith,
    };
  },
});
